<template>
  <v-container
    class="has-background-image fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
      dense
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-col cols="12">
          <div class="text-center ma-3">
            <h1> إكمال تسجيل الحساب</h1>
          </div>
        </v-col>
        <v-card class="glassy-card">
          <v-card-text>
            <v-form
              :disabled="loading"
              @submit.prevent="register"
            >
              <v-text-field
                v-model="form.name"
                label="الاسم الحقيقي"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.name"
                outlined
                required
              />
              <v-text-field
                v-model="form.email"
                label="البريد الإلكتروني"
                type="email"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
                outlined
                required
              />
              <v-text-field
                v-model="form.phone"
                label="رقم الهاتف"
                type="tel"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
                outlined
                required
              />
              <v-text-field
                v-model="form.password"
                label="كلمة المرور"
                type="password"
                prepend-inner-icon="mdi-lock"
                :error-messages="errors.password"
                outlined
                required
              />
              <v-text-field
                v-model="form.password_confirmation"
                label="تأكيد كلمة المرور"
                type="password"
                prepend-inner-icon="mdi-lock"
                :error-messages="errors.password_confirmation"
                outlined
                required
              />
              <v-select
                v-model="form.city"
                :items="cities"
                :error-messages="errors.city"
                label="المحافظة التي تسكن بها"
                outlined
                required
              />
              <v-btn
                color="primary"
                type="submit"
                :loading="loading"
                x-large
                block
                dark
              >
                تأكيد
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        name: process.env.NODE_ENV === 'production' ? '' : 'فتحي ابو الفتوح',
        email: process.env.NODE_ENV === 'production' ? '' : 'fathi@email.com',
        phone: process.env.NODE_ENV === 'production' ? '' : '0946452987',
        city: process.env.NODE_ENV === 'production' ? '' : 'دمشق',
        password: process.env.NODE_ENV === 'production' ? '' : 'password',
        password_confirmation: process.env.NODE_ENV === 'production' ? '' : 'password',
      },
      cities: [
        'دمشق',
        'ريف دمشق',
        'حلب',
        'حمص',
        'حماة',
        'طرطوس',
        'اللاذقية',
        'درعا',
        'دير الزور',
        'إدلب',
        'القنيطرة',
        'الرقة',
        'الحسكة',
        'السويداء',
      ],
      loading: false,
      errors: {},
    }
  },

  methods: {
    login () {
      const self = this
      const payload = self.form
      self.loading = true

      self.$store.dispatch('login', payload).then(() => {
        self.loading = false
        self.$router.push({ name: 'home' })
      }).catch(err => {
        self.loading = false
        self.errors = err.response.data.errors
      })
    },
    register () {
      const self = this
      const payload = this.form
      self.loading = true

      self.axios.get('/sanctum/csrf-cookie').then(() => {
        self.axios.post('/register', payload).then(function () {
          self.$store.commit('login')
          if (self.$route.query.redirect_to) {
            self.$router.push(self.$route.query.redirect_to)
          } else {
            self.$router.push({ name: 'home' })
          }
        }).catch(function (err) {
          self.errors = err.data.errors
          self.loading = false
        })
      })
    },
  },
}
</script>
